<template>
  <div class="add-to-cart">
    <div v-if="emphaze && productCount" class="flex-grow text-center font-bold">Quantité dans le panier (soit {{total}} €)</div>
    <div class="flex justify-around content-center pt-2 pb-2" :class="{'add-to-cart--emphaze': emphaze}">
      <button @click.prevent="add()" v-if="!productCount && autoRemove" class="mt-2 btn text-center" :class="{'w-full': emphaze}">
        <span class="inline-block w-6 h-6  md:inline-block">
          <ShoppingCartIcon/>
          <PlusCircleIcon class="-mt-7 w-4 h-4 ml-3 stroke-primary fill-white" style="stroke-width: 3px;" />
        </span>
        &nbsp;
        <span class="inline hidden sm:block sm:visible">
          Ajouter au panier
        </span>
      </button>
      <template v-else>
        <button
          v-if="orderItem.quantity > 0"
          class="btn px-3 xs:px-5"
          @click.prevent="updateQuantity(orderItem.quantity-quantityStep)"
        >
          -
        </button>
        <button
          v-else
          class="btn px-5"
          @click.prevent="updateQuantity(orderItem.quantity+quantityStep)"
        >
          +
        </button>
        <div style="flex: 0.3 0 3rem;" class="relative">
          <label for="qte" class="sr-only block text-sm font-medium text-gray-700">Quantité</label>
          <div class="rounded-md shadow-sm">
<!--            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">-->
<!--              <span class="text-gray-500 sm:text-sm"> prefix</span>-->
<!--            </div>-->
            <input type="text"
              name="qte"
              :size="productCount.toString().length"
              min="0"
              :value="productCount"
              class="focus:ring-indigo-500 focus:border-indigo-500 block w-full
              text-center
              sm:text-sm border-gray-300 rounded-md"
              aria-describedby="quantity-unit"
              @input="updateQuantity($event.target.value)"
              placeholder="0.00"
            />
            <div class="absolute right-0 pr-0.5 flex items-center pointer-events-none">
              <span class="text-gray-500 text-xs text-sm" id="price-currency"> {{ unit }} </span>
            </div>
          </div>
        </div>
        <button v-if="orderItem.quantity === 0" class="font-medium text-indigo-600 hover:text-indigo-500" @click.prevent="removeFromCart()">
          <TrashIcon class="h-6 w-6 inline sm:h-4 sm:w-4"></TrashIcon> <span class="hidden sm:inline">Supprimer</span><br>
          <span class="hidden lg:inline">du panier</span>
        </button>
        <button class="btn" @click.prevent="updateQuantity(orderItem.quantity+quantityStep)" v-else>+</button>
      </template>
    </div>
    <span v-if="showUnitPrice" class="unit block text-center">
      {{ orderItem?.product?.formatPrice() }}
    </span>

  </div>
</template>

<script setup>
import {computed, onMounted, toRefs} from 'vue';
import TrashIcon from '@heroicons/vue/24/solid/TrashIcon.js';
import ShoppingCartIcon from '@heroicons/vue/24/outline/ShoppingCartIcon.js';
import PlusCircleIcon from '@heroicons/vue/24/outline/PlusCircleIcon.js';

import {getUnitLabel, roundPrice} from "~/src/models/Product";
import {useCartStore} from "~/stores/cart.js";
import {useProductsStore} from "~/stores/products.js";
const props = defineProps({
  emphaze: { type: Boolean, default: () => false },
  productId: {type: [String, Number]},
  showUnitPrice: {type: Boolean, default: () => false},
  autoRemove: {type: Boolean, default: () => true}
});
const cartStore = useCartStore();
const productsStore = useProductsStore();
const {productId, autoRemove} = toRefs(props);
const product = computed(() => productsStore.productById(productId.value));
const productCount = computed(() => cartStore.productCount(productId.value));
const quantityStep = computed(() => product.value.getQuantityStep());
const orderItem = computed(() => cartStore.orderItemByProductId(productId.value));
const total = computed(() => orderItem.value && orderItem.value.getTotal ? roundPrice(orderItem.value.getTotal()) : 0);
const unit = computed(() => {
  if (orderItem.value && orderItem.value?.unit) {
    return getUnitLabel(orderItem.value?.unit)
  }
  return null;
})
const unitLabelLength = computed(() => unit.value.length || 0);

const removeFromCart = async () => {
  cartStore.setItem({productId: productId.value, quantity: 0, autoRemove: true})
};

const updateQuantity = (quantity) => {
  quantity = parseInt(quantity, 10);
  if (quantity <= 0) {
    quantity = 0;
  }
  cartStore.setItem({productId: productId.value, quantity, autoRemove: autoRemove.value})
};

const add = async () => await cartStore.addProductToCart({productId: productId.value});

// Ensure we have the product informations of the saleitem
onMounted(async () => {
  if (!product.value) {
    await productsStore.fetchProduct({id: productId.value});
  }
})
</script>
